import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Footer = styled.footer`
  display: flex;
  margin-top: 1.45rem;
  background: black;
  height: 70px;
  padding: 0 16px;
  box-sizing: border-box;

  @media all and (max-width: 559px) {
    height: auto;
  }
`;

const Navlinks = styled.div`
  margin: 0 auto;
  display: flex;

  @media all and (max-width: 559px) {
    margin: 0;
    display: block;
    text-align: center;
  }
`;

const Navlink = styled.div`
  margin: auto 0;
  padding-top: 20px;
  padding-bottom: 20px;
  a {
    color: white;
    padding: 0 16px;
    text-decoration: none;
    font-size: 0.8rem;

    &:hover {
      color: orange;
    }
  }
  @media all and (max-width: 559px) {
    width: 50%;
    display: inline-block;
  }
`;

const FooterArea = () => {
  return (
    <Footer>
      <Navlinks>
        <Navlink>
          <Link to="/privacy/">プライバシーポリシー</Link>
        </Navlink>
        <Navlink>
          <Link to="/law/">免責事項</Link>
        </Navlink>
        <Navlink>
          <Link to="/sitemap/">サイトマップ</Link>
        </Navlink>
        <Navlink>
          <a
            className="google_form"
            href="https://forms.gle/r5Gk1q6Vqip4X7bg7"
            target="_blank"
            rel="noopener noreferrer"
          >
            お問い合わせ
          </a>
        </Navlink>
      </Navlinks>
    </Footer>
  );
};

export default FooterArea;
